import React, { useState, useEffect, useCallback, useRef, useMemo, forwardRef, useImperativeHandle } from 'react';
import axios from 'axios';
import io from 'socket.io-client';

// Constants
const NEW_MESSAGE_SOUND_PATH = `${process.env.PUBLIC_URL}/new-message.mp3`;
const SOCKET_URL = 'https://barriobucks.com';
const API_BASE_URL = 'https://barriobucks.com/api';

// Initialize Socket.IO
const socket = io(SOCKET_URL, {
  transports: ['websocket', 'polling'],
  withCredentials: true,
  reconnectionAttempts: 5,
  timeout: 10000,
});

// Initialize Axios
const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

// Utility Functions
const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return 'Unknown';
  const cleaned = ('' + phoneNumber).replace(/\D/g, '').slice(-10);
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phoneNumber;
};

const normalizePhoneNumber = (phoneNumber) => {
  return phoneNumber ? phoneNumber.replace(/\D/g, '') : '';
};

// Welcome Popup Component
const WelcomePopup = ({ onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white p-6 rounded-lg shadow-xl">
      <h2 className="text-xl font-bold mb-4">Welcome to Txtr!</h2>
      <p className="mb-4">Your messaging hub is ready.</p>
      <button
        onClick={onClose}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Get Started
      </button>
    </div>
  </div>
);

// Main Txtr Component
const Txtr = forwardRef(({
  searchTerm: initialSearchTerm,
  setIsTxtrLoaded,
  selectedThread,
  setSelectedThread,
  messages,
  setMessages,
  threads,
  setThreads
}, ref) => {
  // State Variables
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const messagesEndRef = useRef(null);
  const [localSearchTerm, setLocalSearchTerm] = useState(initialSearchTerm);
  const [selectedImage, setSelectedImage] = useState(null);
  const [unreadThreads, setUnreadThreads] = useState(new Set());
  const [isAudioEnabled, setIsAudioEnabled] = useState(false);
  const [showWelcomePopup, setShowWelcomePopup] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observerRef = useRef();
  const [audioContext, setAudioContext] = useState(null);
  const audioBufferRef = useRef(null);
  
  // Ref for Alert Interval
  const alertIntervalRef = useRef(null);

  // New State: Controls whether alert sounds are allowed to play
  const [alertSoundsAllowed, setAlertSoundsAllowed] = useState(true);

  // Audio Initialization
  const initializeAudio = useCallback(() => {
    if (audioContext) return; // Already initialized

    const context = new (window.AudioContext || window.webkitAudioContext)();
    setAudioContext(context);

    fetch(NEW_MESSAGE_SOUND_PATH)
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => context.decodeAudioData(arrayBuffer))
      .then(audioBuffer => {
        audioBufferRef.current = audioBuffer;
      })
      .catch(error => console.error('Error loading audio:', error));
  }, [audioContext]);

  const processedMessages = useRef(new Set());

  // Play Sound
  const playSound = useCallback(() => {
    if (audioContext && audioBufferRef.current && isAudioEnabled) {
      const source = audioContext.createBufferSource();
      source.buffer = audioBufferRef.current;
      source.connect(audioContext.destination);
      source.start(0);
    }
  }, [audioContext, isAudioEnabled]);

  // Enable Audio
  const enableAudio = useCallback(() => {
    initializeAudio();
    setIsAudioEnabled(true);
  }, [initializeAudio]);

  // Intersection Observer for Infinite Scrolling
  const lastThreadElementRef = useCallback(node => {
    if (isLoading) return;
    if (observerRef.current) observerRef.current.disconnect();
    observerRef.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        fetchThreads();
      }
    });
    if (node) observerRef.current.observe(node);
  }, [isLoading, hasMore]);


  const threadObserver = useRef(new IntersectionObserver((entries) => {
    entries.forEach(async entry => {
      if (entry.isIntersecting) {
        const thread = JSON.parse(entry.target.dataset.thread);
        if (!thread.customerName) {
          const customerInfo = await fetchCustomerInfo(thread.phone_number);
          if (customerInfo) {
            setThreads(prevThreads => prevThreads.map(t => 
              normalizePhoneNumber(t.phone_number) === normalizePhoneNumber(thread.phone_number)
                ? { ...t, customerName: customerInfo.name }
                : t
            ));
          }
        }
      }
    });
  }, { rootMargin: '50px' }));

  // Fetch Customer Info
  const fetchCustomerInfo = useCallback(async (phone) => {
    try {
      const response = await api.get('/customer-info', { params: { phone } });
      return response.data.customer || null;
    } catch (err) {
      console.error(`Error fetching customer info for ${phone}:`, err);
      return null;
    }
  }, []);

  // Unread Alert Component
  const UnreadAlert = ({ unreadCount, onClearAll }) => (
    <div className="bg-blue-200 p-2 flex justify-between items-center">
      <span>{unreadCount} unread</span>
      <button onClick={onClearAll} className="text-blue-600 hover:text-blue-800">
        Clear all
      </button>
    </div>
  );

  // Clear All Unread Threads
  const clearAllUnread = useCallback(() => {
    setUnreadThreads(new Set());
    setAlertSoundsAllowed(false); // Stop alert sounds
  }, []);

  // Fetch Threads
  const fetchThreads = useCallback(async (pageToFetch = page, searchTerm = localSearchTerm) => {
    if (!hasMore) return;
    setIsLoading(true);
    try {
      const response = await api.get('/threads', {
        params: { page: pageToFetch, limit: 20, search: searchTerm }
      });
      
      const newThreads = response.data.map(thread => ({
        ...thread,
        customerName: null // Initialize with null, will be fetched when visible
      }));
      
      setThreads(prevThreads => {
        const combined = [...prevThreads, ...newThreads];
        const uniqueThreadsMap = new Map(combined.map(thread => [normalizePhoneNumber(thread.phone_number), thread]));
        const uniqueThreads = Array.from(uniqueThreadsMap.values());
        return uniqueThreads.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      });
  
      setHasMore(newThreads.length === 20);
      setPage(pageToFetch + 1);
      setError(null);
    } catch (error) {
      console.error('Error fetching threads:', error);
      setError('Failed to fetch threads. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [hasMore, localSearchTerm, setThreads, page]);

  // Fetch Messages
  const fetchMessages = useCallback(async (phoneNumber) => {
    try {
        const response = await api.get(`/messages/${phoneNumber}`);
        setMessages(prevMessages => {
            console.log('Previous messages:', prevMessages.length);
            console.log('New messages from API:', response.data.length);

            const newMessages = response.data.filter(newMsg =>
                !prevMessages.some(prevMsg =>
                    prevMsg.id === newMsg.id ||
                    (prevMsg.body === newMsg.body &&
                        Math.abs(new Date(prevMsg.received_at) - new Date(newMsg.received_at)) < 5000)
                )
            );
            
            // Move this log AFTER newMessages is defined
            console.log('Filtered new messages:', newMessages.length);
            
            return [...prevMessages, ...newMessages];
        });
    } catch (error) {
        console.error('Error fetching messages:', error);
        setError('Failed to fetch messages. Please try again.');
    }
}, [setMessages]);

  // Refresh Messages
  const refreshMessages = useCallback(() => {
    if (selectedThread) {
      fetchMessages(selectedThread.phone_number);
    }
  }, [selectedThread, fetchMessages]);

  // Update Thread Function (Refactored)
  const updateThread = useCallback(async (phoneNumber, lastMessage, timestamp) => {
    const normalizedPhone = normalizePhoneNumber(phoneNumber);
    const incomingTimestamp = new Date(timestamp);
  
    console.log(`Updating thread for phone: ${normalizedPhone} with message: "${lastMessage}" at ${timestamp}`);
  
    // Get customer info immediately
    const customerInfo = await fetchCustomerInfo(phoneNumber);
  
    setThreads(prevThreads => {
      const threadIndex = prevThreads.findIndex(thread => 
        normalizePhoneNumber(thread.phone_number) === normalizedPhone
      );
  
      if (threadIndex !== -1) {
        const existingThread = prevThreads[threadIndex];
        const existingTimestamp = new Date(existingThread.timestamp);
  
        if (incomingTimestamp > existingTimestamp) {
          const updatedThread = {
            ...existingThread,
            last_message: lastMessage,
            timestamp: timestamp,
            customerName: customerInfo?.name || existingThread.customerName
          };
          return [
            updatedThread,
            ...prevThreads.slice(0, threadIndex),
            ...prevThreads.slice(threadIndex + 1)
          ];
        }
        return prevThreads;
      } else {
        const newThread = {
          phone_number: phoneNumber,
          last_message: lastMessage,
          timestamp: timestamp,
          customerName: customerInfo?.name
        };
        return [newThread, ...prevThreads];
      }
    });
  }, [setThreads, fetchCustomerInfo]);

  // Handle Outbound Message
  const handleOutboundMessage = useCallback(async (message) => {
    console.log('Outbound message received:', message);
  
    // Play audio alert if enabled
    if (isAudioEnabled) {
      playSound();
    }
  
    // Identify the thread phone number for outbound message
    const threadPhoneNumber = message.to_number;
    const normalizedThreadPhone = normalizePhoneNumber(threadPhoneNumber);
  
    // Fetch customer info if name is not provided (optional logic)
    let customerName = message.customerName;
    if (!customerName) {
      const customerInfo = await fetchCustomerInfo(threadPhoneNumber);
      customerName = customerInfo ? customerInfo.name : null;
    }
  
    // Update the thread list so last_message and timestamp show correctly
    updateThread(threadPhoneNumber, message.body, message.received_at);
  
    // Only add the new outbound message to our 'messages' if this phone matches the selected thread
    if (selectedThread && normalizePhoneNumber(selectedThread.phone_number) === normalizedThreadPhone) {
      setMessages(prevMessages => {
        const messageExists = prevMessages.some(msg =>
          msg.id === message.id ||
          (msg.body === message.body &&
            Math.abs(new Date(msg.received_at) - new Date(message.received_at)) < 5000)
        );
  
        if (messageExists) {
          console.log('Message already exists in state, not adding');
          return prevMessages;
        }
  
        console.log('Adding new message to state');
        return [...prevMessages, message].sort((a, b) => 
          new Date(a.received_at) - new Date(b.received_at)
        );
      });
    }
}, [
    selectedThread,
    setMessages,
    updateThread,
    fetchCustomerInfo,
    isAudioEnabled,
    playSound
]);

  // Handle New Message
  const handleNewMessage = useCallback((message) => {
    // Add message ID tracking
    const messageKey = `${message.id}-${message.received_at}`;
    if (processedMessages.current.has(messageKey)) {
      console.log('Txtr: Message already processed:', messageKey);
      return;
    }
    processedMessages.current.add(messageKey);

    // Determine if this message belongs to the currently selected thread
    const messageThread = normalizePhoneNumber(message.from_number) === normalizePhoneNumber(process.env.REACT_APP_TWILIO_PHONE_NUMBER)
    ? normalizePhoneNumber(message.to_number)
    : normalizePhoneNumber(message.from_number);
    
    const belongsToSelectedThread = selectedThread && 
        normalizePhoneNumber(selectedThread.phone_number) === messageThread;
  
    // Only add message to current thread if it belongs there
    if (belongsToSelectedThread) {
      setMessages(prevMessages => {
        const messageExists = prevMessages.some(msg =>
          msg.id === message.id ||
          (msg.body === message.body &&
            Math.abs(new Date(msg.received_at) - new Date(message.received_at)) < 5000)
        );
  
        if (messageExists) {
          return prevMessages;
        }
  
        return [...prevMessages, message].sort((a, b) =>
          new Date(a.received_at) - new Date(b.received_at)
        );
      });
    }
  
    // Always update the thread list
    const threadPhone = message.from_number === process.env.REACT_APP_TWILIO_PHONE_NUMBER 
      ? message.to_number 
      : message.from_number;
      
    updateThread(threadPhone, message.body, message.received_at);
  
    // Handle notifications for new messages
    if (message.from_number !== process.env.REACT_APP_TWILIO_PHONE_NUMBER) {
      playSound();
      setUnreadThreads(prev => {
        const newSet = new Set(prev);
        newSet.add(threadPhone);
        return newSet;
      });
      setAlertSoundsAllowed(true);
    }
  }, [selectedThread, setMessages, updateThread, playSound, setUnreadThreads, setAlertSoundsAllowed]);
  

  // Handle Thread Selection
  const handleThreadSelect = useCallback((thread) => {
    setSelectedThread(thread);
    setError(null);
    setMessages([]); // Add this back
    setUnreadThreads(prev => {
        const newSet = new Set(prev);
        newSet.delete(thread.phone_number);
        return newSet;
    });
    setAlertSoundsAllowed(false);
    fetchMessages(thread.phone_number);
}, [fetchMessages]);

  // Add New Thread
  const addNewThread = useCallback((newThread) => {
    setThreads(prevThreads => {
      const normalizedPhone = normalizePhoneNumber(newThread.phone_number);
      const threadIndex = prevThreads.findIndex(thread => normalizePhoneNumber(thread.phone_number) === normalizedPhone);

      if (threadIndex !== -1) {
        const existingThread = prevThreads[threadIndex];
        const incomingTimestamp = new Date(newThread.timestamp);
        const existingTimestamp = new Date(existingThread.timestamp);

        if (incomingTimestamp > existingTimestamp) {
          const updatedThread = {
            ...existingThread,
            last_message: newThread.last_message,
            timestamp: newThread.timestamp,
            customerName: newThread.customerName || existingThread.customerName,
          };
          const updatedThreads = [
            updatedThread,
            ...prevThreads.slice(0, threadIndex),
            ...prevThreads.slice(threadIndex + 1)
          ];
          return updatedThreads;
        }
      }

      // If thread does not exist or incoming message is older, add it to the top
      return [newThread, ...prevThreads];
    });
    setSelectedThread(newThread);
    setMessages([]);
  }, [setThreads, setSelectedThread, setMessages]);

  // Filtered Threads based on Search Term
  const filteredThreads = useMemo(() => {
    if (!threads) return [];
    const searchTermLower = localSearchTerm.toLowerCase().trim();
    const containsLetters = /[a-zA-Z]/.test(searchTermLower);
    const phoneNumberMatch = searchTermLower.replace(/\D/g, '');
  
    // Get dates for comparison
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const twoWeeksAgo = new Date(today);
    twoWeeksAgo.setDate(today.getDate() - 14);
  
    const filtered = threads.filter((thread) => {
      if (!thread || !thread.phone_number) return false;
      
      // Only include threads from last 14 days
      const threadDate = new Date(thread.timestamp);
      if (threadDate < twoWeeksAgo) return false;
  
      const normalizedThreadPhone = normalizePhoneNumber(thread.phone_number);
  
      if (containsLetters) {
        if (thread.customerName) {
          const customerNameLower = thread.customerName.toLowerCase();
          const searchTerms = searchTermLower.split(/\s+/);
          return searchTerms.every(term => customerNameLower.includes(term));
        }
        if (!thread.customerInfo_fetching) {
          thread.customerInfo_fetching = true;
          fetchCustomerInfo(thread.phone_number).then(info => {
            if (info && info.name) {
              setThreads(current => 
                current.map(t => 
                  normalizePhoneNumber(t.phone_number) === normalizedThreadPhone
                    ? { ...t, customerName: info.name }
                    : t
                )
              );
            }
          });
        }
        return false;
      }
  
      return normalizedThreadPhone.includes(phoneNumberMatch);
    });
  
    return filtered.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
  }, [threads, localSearchTerm, fetchCustomerInfo, setThreads]);

  // Expose Methods to Parent via Ref
  useImperativeHandle(ref, () => ({
    updateSearchTerm: (term) => {
      setLocalSearchTerm(term);
    },
    addNewThread,
    refreshMessages,
    addNewMessage: (message) => {
      setMessages(prevMessages => {
        // Check if the message already exists in the state
        const messageExists = prevMessages.some(msg =>
          msg.id === message.id ||
          (msg.body === message.body &&
            Math.abs(new Date(msg.received_at) - new Date(message.received_at)) < 5000)
        );

        if (messageExists) {
          console.log('Message already exists in state, not adding');
          return prevMessages;
        }

        console.log('Adding new message to state');
        return [...prevMessages, message];
      });
    },
    updateThread
  }));

  // Effect: Show Welcome Popup and Initialize Audio
  useEffect(() => {
    if (showWelcomePopup) {
      const timer = setTimeout(() => {
        initializeAudio();
        enableAudio();
        setShowWelcomePopup(false);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [showWelcomePopup, enableAudio, initializeAudio]);

  // Effect: Fetch Threads and Setup Socket Listeners
  useEffect(() => {
    fetchThreads();
    setIsTxtrLoaded(true);
    socket.on('newMessage', handleNewMessage);

    return () => {
      setIsTxtrLoaded(false);
      socket.off('newMessage', handleNewMessage);
    };
  }, [fetchThreads, setIsTxtrLoaded, handleNewMessage]);

  // Effect: Setup Outbound Message Listener
  useEffect(() => {
    socket.on('outboundMessage', handleOutboundMessage);

    return () => {
      socket.off('outboundMessage', handleOutboundMessage);
    };
  }, [handleOutboundMessage]);

  // Effect: Fetch Messages when Selected Thread Changes (causing double fetch)
  // useEffect(() => {
  //   if (selectedThread) {
  //     fetchMessages(selectedThread.phone_number);
  //   }
  // }, [selectedThread, fetchMessages]);

  // Effect: Scroll to Bottom on Messages Update
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [messages]);

  // Effect: Setup Repeating Alert Sound
  useEffect(() => {
    // Only set up alert sounds if allowed, there are unread threads, and audio is enabled
    if (alertSoundsAllowed && unreadThreads.size > 0 && isAudioEnabled) {
      if (!alertIntervalRef.current) {
        // Play the sound immediately upon detecting unread threads
        playSound();

        // Set up the interval to play sound every minute
        alertIntervalRef.current = setInterval(() => {
          playSound();
        }, 60000);
      }
    } else {
      // If no unread threads or alert sounds are not allowed, clear the interval
      if (alertIntervalRef.current) {
        clearInterval(alertIntervalRef.current);
        alertIntervalRef.current = null;
      }
    }

    // Cleanup on unmount or when dependencies change
    return () => {
      if (alertIntervalRef.current) {
        clearInterval(alertIntervalRef.current);
        alertIntervalRef.current = null;
      }
    };
  }, [alertSoundsAllowed, unreadThreads, isAudioEnabled, playSound]);

  // Effect: Stop Alerts on User Interaction
  useEffect(() => {
    const handleUserInteraction = () => {
      if (alertSoundsAllowed && unreadThreads.size > 0) {
        setAlertSoundsAllowed(false); // Stop alert sounds
        if (alertIntervalRef.current) {
          clearInterval(alertIntervalRef.current);
          alertIntervalRef.current = null;
        }
      }
    };

    window.addEventListener('click', handleUserInteraction);
    window.addEventListener('keydown', handleUserInteraction);
    window.addEventListener('touchstart', handleUserInteraction); // For mobile devices

    return () => {
      window.removeEventListener('click', handleUserInteraction);
      window.removeEventListener('keydown', handleUserInteraction);
      window.removeEventListener('touchstart', handleUserInteraction);
    };
  }, [alertSoundsAllowed, unreadThreads]);

  // Attachment Rendering Helper Functions
  const getAttachmentUrl = (filename) => {
    return `/uploads/${filename}`;
  };

  const renderAttachment = (attachment) => {
    if (!attachment || !attachment.filename) {
      console.error('Invalid attachment:', attachment);
      return null;
    }

    const attachmentUrl = getAttachmentUrl(attachment.filename);

    if (attachment.contentType.startsWith('image/')) {
      return (
        <>
          <img
            src={attachmentUrl}
            alt={attachment.filename}
            className="max-w-full h-auto cursor-pointer"
            onClick={() => setSelectedImage(attachmentUrl)}
            onError={(e) => {
              console.error('Image load error:', e);
              e.target.onerror = null;
              e.target.src = '/path/to/fallback/image.png';
            }}
          />
          {selectedImage && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={() => setSelectedImage(null)}>
              <img src={selectedImage} alt="Full size" className="max-w-full max-h-full" />
            </div>
          )}
        </>
      );
    } else if (attachment.contentType.startsWith('video/')) {
      return (
        <video src={attachmentUrl} controls className="max-w-full h-auto">
          Your browser does not support the video tag.
        </video>
      );
    } else if (attachment.contentType.startsWith('audio/')) {
      return (
        <audio src={attachmentUrl} controls className="w-full">
          Your browser does not support the audio tag.
        </audio>
      );
    } else {
      return (
        <a href={attachmentUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline block">
          Download {attachment.filename}
        </a>
      );
    }
  };

  // Render Welcome Popup if Needed
  if (showWelcomePopup) {
    return <WelcomePopup onClose={() => setShowWelcomePopup(false)} />;
  }

  // Main Render
  return (
    <div className="flex h-full bg-white pt-4 pb-20">
      <div className="w-1/3 border-r border-gray-300 h-full overflow-y-auto">
        {unreadThreads.size > 0 && (
          <UnreadAlert
            unreadCount={unreadThreads.size}
            onClearAll={clearAllUnread}
          />
        )}
        {filteredThreads.map((thread, index) => (
        <div
          key={normalizePhoneNumber(thread.phone_number)}
          ref={node => {
            if (node) {
              node.dataset.thread = JSON.stringify(thread);
              threadObserver.current.observe(node);
            }
            if (index === filteredThreads.length - 1) lastThreadElementRef(node);
          }}
          className={`p-4 border-b border-gray-200 cursor-pointer ${
            selectedThread?.phone_number === thread.phone_number
              ? 'bg-blue-100'
              : 'hover:bg-gray-100'
          } ${
            new Date(thread.timestamp).toDateString() === new Date().toDateString()
              ? 'opacity-100'
              : 'opacity-70'
          }`}
          onClick={() => handleThreadSelect(thread)}
        >
          <div className="flex items-center">
            {unreadThreads.has(thread.phone_number) && (
              <div className="w-2 h-2 bg-blue-500 rounded-full mr-2 flex-shrink-0"></div>
            )}
            <div className="font-semibold text-gray-700 truncate flex-grow">
              {thread.customerName || formatPhoneNumber(thread.phone_number)}
            </div>
          </div>
          {thread.customerName && (
            <div className="text-sm text-gray-600">{formatPhoneNumber(thread.phone_number)}</div>
          )}
          <div className="text-sm text-gray-600 truncate">{thread.last_message}</div>
          <div className={`text-xs ${
            new Date(thread.timestamp).toDateString() === new Date().toDateString()
              ? 'text-blue-600 font-semibold'
              : 'text-gray-400'
          }`}>
            {new Date(thread.timestamp).toLocaleString()}
          </div>
        </div>
        ))}
        {isLoading && <div className="p-4 text-gray-500">Loading more threads...</div>}
      </div>

      <div className="w-2/3 flex flex-col h-full">
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        {selectedThread ? (
          <div className="flex-1 overflow-y-auto">
            <div className="p-4">
              {messages.map((message) => {
                return (
                  <div
                    key={`${message.id}-${message.received_at}`}
                    className={`mb-4 flex ${
                      normalizePhoneNumber(message.from_number) === normalizePhoneNumber(process.env.REACT_APP_TWILIO_PHONE_NUMBER)
                        ? 'justify-end'
                        : 'justify-start'
                    }`}
                  >
                    <div
                      className={`p-2 rounded-lg max-w-xs ${
                        normalizePhoneNumber(message.from_number) === normalizePhoneNumber(process.env.REACT_APP_TWILIO_PHONE_NUMBER)
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-300 text-black'
                      }`}
                    >
                      {message.body}
                      {message.attachments && message.attachments.length > 0 && (
                        <div>
                          <p>Attachments ({message.attachments.length}):</p>
                          {message.attachments.map((attachment, index) => (
                            <div key={index} className="mt-2">
                              {renderAttachment(attachment)}
                            </div>
                          ))}
                        </div>
                      )}
                      <div
                        className={`text-xs mt-1 ${
                          normalizePhoneNumber(message.from_number) === normalizePhoneNumber(process.env.REACT_APP_TWILIO_PHONE_NUMBER)
                            ? 'text-gray-200'
                            : 'text-gray-500'
                        }`}
                      >
                        {new Date(message.received_at).toLocaleString()}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div ref={messagesEndRef} />
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center flex-1 text-gray-500">
            Select a thread to start chatting
          </div>
        )}
      </div>
    </div>
  );

});

export default Txtr;
